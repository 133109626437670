import React from 'react';
import { Link } from 'react-router-dom';
// import Deatils from '../../../assets/image/services/service-details-1.jpg'

function Allresources() {

    const settings = {
        items: 4,
        nav:false,
        margin:30,
        loop: true,
        autoplay:true,
        smartSpeed:2000,
        responsive:{
            0:{
                    items:1,
            },
            575:{
                    items:2,
            },
            767:{
                    items:3,
            },
            991: {
                items:4,
            }
        }
      };

  return (
    <>
        <section className="portfolio-details">
            <div className="container">
                <img src={"assets/images/portfolio/resources-1.jpg"} className="img-fluid" alt="img-109" />
                <div className="portfolio-details-info">
                
               
                <div className="portfolio-details-info-item portfolio-details-info-socials">
                    <a href="https://twitter.com/">
                        <i className="fa-brands fa-twitter" />
                    </a>
                    <a href="https://www.facebook.com/">
                        <i className="fa-brands fa-facebook" />
                    </a>
                    <a href="https://in.pinterest.com/">
                        <i className="fa-brands fa-pinterest-p" />
                    </a>
                    <a href="https://www.instagram.com/">
                        <i className="fa-brands fa-instagram" />
                    </a>
                </div>
                
                </div>
                
                <div className="portfolio-details-content">
                <div className="portfolio-details-content-title">
                    <h3>Access all LNP rescources</h3>
                </div>
               
                {/* <div className="portfolio-details-content-text">
                    <p>
                    Reporting a crime to the police is a crucial process that enables 
                    law enforcement to address and investigate illegal activities promptly. 
                    When individuals report crimes, they provide essential information that 
                    can lead to the apprehension of offenders and the prevention of further 
                    criminal acts..{" "}
                    </p>
                    <p>
                    The process typically involves contacting local law enforcement 
                    through emergency numbers, visiting police stations, or using online reporting 
                    systems where available. Accurate and detailed reporting, including descriptions 
                    of the incident, involved parties, and any evidence or witnesses, helps police 
                    officers build strong cases and ensures that appropriate actions are taken to 
                    protect the community and uphold the law.
                    </p>
                    <p>
                    The effectiveness of crime reporting largely depends on the detail and accuracy of 
                    the information provided by the witness or victim. When reporting a crime, it is 
                    crucial to include as many specifics as possible, such as the time and location of 
                    the incident, descriptions of any suspects, vehicles involved, and any potential 
                    witnesses. Additionally, physical evidence, such as photographs, videos, or other 
                    tangible items, can be invaluable in supporting the police investigation. The more 
                    comprehensive the report, the better equipped law enforcement will be to solve the 
                    crime and bring perpetrators to justice..{" "}
                    </p>
                    
                </div> */}
                <br/>
                <br/>
                <br/>
        
                    <div className="container">
                <div className="row row-gutter-30">
                <div className="col-lg-6 col-xl-4">
                    <div className="service-one-card">
                    <div className="service-one-icon">
                        <Link to="#">
                            <i className="flaticon-pdf" />
                        </Link>
                    </div>
                    
                    <div className="service-one-card-content">
                        <h4>
                         Police Act 2016 
                        </h4>
                        <p>Click below button to download the Liberia National Police Act 2016.</p>
                        <Link to="https://drive.google.com/file/d/1KVjR_emn6JnUGsGovnUWNhvBmnOO94vS/view?usp=drive_link" className="btn btn-primary">Download</Link>
                    </div>
                    
                    </div>
                    
                </div>
                
                <div className="col-lg-6 col-xl-4">
                    <div className="service-one-card">
                    <div className="service-one-icon">
                        <Link to="#">
                        <i className="flaticon-pdf" />
                        </Link>
                    </div>
                    
                    <div className="service-one-card-content">
                        <h4>
                         Revised Act to Ban 
                        </h4>
                        <p>Download the Revised Act to Ban Trafficking in Persons 2021.</p>
                        <Link to="https://drive.google.com/file/d/1bRXZMScCfanjo22Yk_ytDgXq1sx-ilIx/view?usp=drive_link" className="btn btn-primary">Download</Link>
                    </div>
                    
                    </div>
                    
                </div>
                
                <div className="col-lg-6 col-xl-4">
                    <div className="service-one-card">
                    <div className="service-one-icon">
                        <Link to="#">
                        <i className="flaticon-pdf" />
                        </Link>
                    </div>
                    
                    <div className="service-one-card-content">
                        <h4>
                         Freedom of Information
                        </h4>
                        <p>Download the Freedom of Information Act 2010.</p>
                        <Link to="https://drive.google.com/file/d/1OQ06j4CkaF2orOCXYAWePt1GVqKIMc6P/view?usp=drive_link" className="btn btn-primary">Download</Link>
                    </div>
                    
                    </div>
                    
                </div>
                
                <div className="col-lg-6 col-xl-4">
                    <div className="service-one-card">
                    <div className="service-one-icon">
                        <Link to="#">
                        <i className="flaticon-pdf" />
                        </Link>
                    </div>
                    
                    <div className="service-one-card-content">
                        <h4>
                         Annual Report
                        </h4>
                        <p>Download the Liberia National Police Annual Report.</p>
                        <Link to="https://drive.google.com/file/d/1XCQ56uR5WfNcpNRn3JgEUFzslxygSkeZ/view?usp=drive_link" className="btn btn-primary">Download</Link>
                    </div>
                    
                    </div>
                    
                </div>
                
                <div className="col-lg-6 col-xl-4">
                    <div className="service-one-card">
                    <div className="service-one-icon">
                        <Link to="#">
                        <i className="flaticon-pdf" />
                        </Link>
                    </div>
                    
                    <div className="service-one-card-content">
                        <h4>
                         Trafficking in Persons
                        </h4>
                        <p>Download the LNP Trafficking in Persons Handbook.</p>
                        <Link to="https://drive.google.com/file/d/14qleF_TIFouLxyLs1hCpUBfakz2Uuz6-/view?usp=drive_link" className="btn btn-primary">Download</Link>
                    </div>
                    
                    </div>
                    
                </div>
                
                <div className="col-lg-6 col-xl-4">
                    <div className="service-one-card">
                    <div className="service-one-icon">
                        <Link to="#">
                        <i className="flaticon-pdf" />
                        </Link>
                    </div>
                    
                    <div className="service-one-card-content">
                        <h4>
                         FOI Brochure
                        </h4>
                        <p>Download the LNP Freedom of Information awareness brochure.</p>
                        <Link to="https://drive.google.com/file/d/1pfIywQBEqEPoP7vRRksSNGzTHBb_Swg8/view?usp=drive_link" className="btn btn-primary">Download</Link>
                    </div>
                    
                    </div>
                    
                </div>
                <div className="col-lg-6 col-xl-4">
                    <div className="service-one-card">
                    <div className="service-one-icon">
                        <Link to="#">
                        <i className="flaticon-pdf" />
                        </Link>
                    </div>
                    
                    <div className="service-one-card-content">
                        <h4>
                         IPO Profile
                        </h4>
                        <p>Download the LNP International Police Organisation Profile.</p>
                        <Link to="https://drive.google.com/file/d/1ov_6tmI1KFNSJpwHX__oFHLKCChQydfO/view?usp=drive_link" className="btn btn-primary">Download</Link>
                    </div>
                    
                    </div>
                    
                </div>
                <div className="col-lg-6 col-xl-4">
                    <div className="service-one-card">
                    <div className="service-one-icon">
                        <Link to="#">
                        <i className="flaticon-pdf" />
                        </Link>
                    </div>
                    
                    <div className="service-one-card-content">
                        <h4>
                         Service Delivery
                        </h4>
                        <p>Download the Liberia National Police Service Delivery Chapter.</p>
                        <Link to="https://drive.google.com/file/d/1SWduLqAgz9XYyeoVo3m8uR1nitdkFD7c/view?usp=drive_link" className="btn btn-primary">Download</Link>
                    </div>
                    
                    </div>
                    
                </div>
                
                </div>
                
            </div>
                
                </div>
               
            </div>
        </section>

        <section className="cta-three">
            <div className="cta-three-inner">
                <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="cta-three-content text-center">
                        <h2 className="section-title text-white">
                            The Liberia Natioal Police Offers a Wide <br /> Range of Needed Services
                        </h2>
                        <Link to="/contact" className="btn btn-primary"> Discover More{" "} </Link>
                    </div>
                    
                    </div>
                    
                </div>
                
                </div>
                
            </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/> 
                    <br/> 
                    <br/> 
        </section>
             

    </>
  )
}

export default Allresources

                    