import React from 'react'
import { Link } from 'react-router-dom'

function Blog() {
  return (
    <>
        <section className="blog-section">
            <div className="container">
                <div className="blog-box">
                <div className="section-title-box text-center">
                    <div className="section-tagline">DIRECT FROM OUR NEWS POSTS</div>
                    <h2 className="section-title">Latest News <br /> and Press Releases </h2>
                </div>
                </div>
                <div className="row row-gutter-y-155">
                
                <div className="col-lg-4">
                    <div className="blog-card">
                    <div className="blog-card-image">
                        <img src={"assets/images/blog/blog-6.png"} className="img-fluid" alt="img-22" />
                        <Link to="/newsdetails" > </Link>
                    </div>
                    
                    <div className="blog-card-date">
                        <Link to="/#">21FEB</Link>
                    </div>
                    
                    <div className="blog-card-content">
                        <div className="blog-card-meta">
                        {/* <span className="author">
                            by<Link to="/#">Admin</Link>
                        </span> */}
                       
                        {/* <span className="comment">
                            <Link to="/#">20 Comments</Link>
                        </span>
                        */}
                        </div>
                        
                        <h4>
                        <Link to="/newsdetails">
                        LNP dedicated a new police depot in Kakata 
                        </Link>
                        </h4>
                        <p>
                        The MarGibi County District #3 Representative Hon Ellen A Attoh-Wreh has constructed a multi-purpose Police Depot in her district..
                        </p>
                    </div>
                  
                    </div>
                    
                </div>
                <div className="col-lg-4">
                    <div className="blog-card">
                    <div className="blog-card-image">
                        <img src={"assets/images/blog/blog-5.png"} className="img-fluid" alt="img-22" />
                        <Link to="/newsdetails" > </Link>
                    </div>
                    
                    <div className="blog-card-date">
                        <Link to="/#">28JAN</Link>
                    </div>
                    
                    <div className="blog-card-content">
                        <div className="blog-card-meta">
                        {/* <span className="author">
                            by<Link to="/#">Admin</Link>
                        </span> */}
                       
                        {/* <span className="comment">
                            <Link to="/#">20 Comments</Link>
                        </span>
                        */}
                        </div>
                        
                        <h4>
                        <Link to="/newsdetails">
                        Rep. Barry Breaks Ground for Police Station
                        </Link>
                        </h4>
                        <p>
                        In an effort to strengthen the fight against crimes by the Liberia National Police within the Township of Louisiana, Lower Montserrado County, Hon. Barry..
                        </p>
                    </div>
                  
                    </div>
                    
                </div>
                <div className="col-lg-4">
                    <div className="blog-card">
                    <div className="blog-card-image">
                        <img src={"assets/images/blog/blog-4.png"} className="img-fluid" alt="img-22" />
                        <Link to="/newsdetails" > </Link>
                    </div>
                    
                    <div className="blog-card-date">
                        <Link to="/#">22NOV</Link>
                    </div>
                    
                    <div className="blog-card-content">
                        <div className="blog-card-meta">
                        {/* <span className="author">
                            by<Link to="/#">Admin</Link>
                        </span> */}
                       
                        {/* <span className="comment">
                            <Link to="/#">20 Comments</Link>
                        </span>
                        */}
                        </div>
                        
                        <h4>
                        <Link to="/newsdetails">
                        LNP deploy more police officers for peacekeeping
                        </Link>
                        </h4>
                        <p>
                        Liberia National Police deploy more police officers for peacekeeping efforts to strengthen security, maintain law and order, and support the restoration..
                        </p>
                    </div>
                  
                    </div>
                    
                </div>

                <div className="col-lg-4">
                    <div className="blog-card">
                    <div className="blog-card-image">
                        <img src={"assets/images/blog/blog-1.png"} className="img-fluid" alt="img-22" />
                        <Link to="/newsdetails" > </Link>
                    </div>
                    
                    <div className="blog-card-date">
                        <Link to="/#">23DEC</Link>
                    </div>
                    
                    <div className="blog-card-content">
                        <div className="blog-card-meta">
                        {/* <span className="author">
                            by<Link to="/#">Admin</Link>
                        </span> */}
                       
                        {/* <span className="comment">
                            <Link to="/#">20 Comments</Link>
                        </span>
                        */}
                        </div>
                        
                        <h4>
                        <Link to="/newsdetails">
                        LNP Initiates Rapid Drug Testing for PSU Officers
                        </Link>
                        </h4>
                        <p>
                        The Liberia National Police (LNP), in collaboration with the Madina Laboratory and Diagnostics Center, has initiated a rapid substance and drug testing..
                        </p>
                    </div>
                  
                    </div>
                    
                </div>
               
                <div className="col-lg-4">
                    <div className="blog-card">
                    <div className="blog-card-image">
                        <img src={"assets/images/blog/blog-3.png"} className="img-fluid" alt="img-23" />
                        <Link to="/newsdetails" />
                    </div>
                    
                    <div className="blog-card-date">
                        <Link to="/#">16JAN</Link>
                    </div>
                    
                    <div className="blog-card-content">
                        <div className="blog-card-meta">
                        {/* <span className="author">
                            by<Link to="/#">Admin</Link>
                        </span>
                       
                        <span className="comment">
                            <Link to="/#">72 Comments</Link>
                        </span> */}
                       
                        </div>
                        
                        <h4>
                        <Link to="/newsdetails"> LNP Superintendent Earned A Master's Degree</Link>
                        </h4>
                        <p>
                        Superintendent Morris S. Langama, a dedicated officer of the Liberia National Police (LNP), has achieved significant milestones in his academic.. 
                        </p>
                    </div>
                  
                    </div>
                    
                </div>
               
                <div className="col-lg-4">
                    <div className="blog-card">
                    <div className="blog-card-image">
                        <img src={"assets/images/blog/blog-2.png"} className="img-fluid" alt="img-24" />
                        <Link to="/newsdetails" />
                    </div>
                    
                    <div className="blog-card-date">
                        <Link to="/#">18JAN</Link>
                    </div>
                    
                    <div className="blog-card-content">
                        <div className="blog-card-meta">
                        {/* <span className="author">
                            by<Link to="/#">Admin</Link>
                        </span>
                       
                        <span className="comment">
                            <Link to="/#">52 Comments</Link>
                        </span> */}
                       
                        </div>
                        
                        <h4>
                        <Link to="/newsdetails">
                        Crackdown on Police Corruption
                        </Link>
                        </h4>
                        <p>
                        This afternoon, while on my way from Nimba, I saw something really concerning. Some highway patrol officers were bothering drivers and taking bribes..
                        </p>
                    </div>
                  
                    </div>
                    
                </div>
               
                </div>
                
            </div>
        </section>

    </>
  )
}

export default Blog